import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { axiosClient } from 'src/lib/api/axios';
import qs from 'qs';

const useQueryFn = <TQueryFnData, TData>({
  queryKey,
  options = {},
  queryStringOptions = {},
  enabled = true,
}: {
  queryKey: any;
  options?: UseQueryOptions;
  queryStringOptions?: any;
  enabled?: boolean;
}) => {
  const url = Array.isArray(queryKey) ? queryKey[0] : queryKey;
  const data = Array.isArray(queryKey) ? queryKey[1] : null;
  return useQuery<TQueryFnData, any, TData>(
    {
      //@ts-ignore
      queryKey,
      queryFn: async () => {
        return await axiosClient({
          method: 'GET',
          url: url + qs.stringify(data, { addQueryPrefix: true, ...queryStringOptions }),
        }).then((res) => res.data);
      },
      enabled,
      staleTime: 0,
    },
    {
      onError: (error: any) => {
        const response = error?.response;
        if (response) {
          console.log('response', response);
        }
      },
      ...options,
    }
  );
};

export default useQueryFn;
