import React from 'react';
import TitleCbt from 'app.feature/academy/component/title/TitleCbt';
import Category from 'app.feature/academy/cbt.category/component/pc/Category';
import CategorySearch from 'app.feature/academy/cbt.category/component/common/CategorySearch';

const CategoryPc = ({ examType, examList }: any) => {
  return (
    <div>
      <div className="header-wrapper">
        <TitleCbt title="시험 CBT" level={1} />
        <CategorySearch />
      </div>
      <Category examType={examType} examList={examList} />
    </div>
  );
};

export default CategoryPc;
