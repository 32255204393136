import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import TitleCbt from 'src/app.feature/academy/component/title/TitleCbt';
import CbtSlide from 'src/app.feature/academy/cbt.best/component/CbtSlide';
import { Grid, Navigation } from 'swiper/modules';
import ArrowSlider from 'src/app.feature/academy/component/icon/arrow-slider.svg';
import useQueryFn from 'app.hooks/useQueryFn';
import Link from 'next/link';

const ScreenCbtBest: React.FC = () => {
  const { data: bestCbt } = useQueryFn<any, any>({
    queryKey: ['/apiV2/exams/best'],
    queryStringOptions: { indices: false },
  });

  const navigationPrevRef = useRef<HTMLButtonElement>(null);
  const navigationNextRef = useRef<HTMLButtonElement>(null);

  return (
    <StyledWrapper>
      <TitleCbt title={'인기 CBT'} level={2} />
      <div className="slider-wrapper">
        <div className="arrow-wrapper">
          <button className="arrow-left" ref={navigationPrevRef}>
            <ArrowSlider />
          </button>
          <button className="arrow-right" ref={navigationNextRef}>
            <ArrowSlider />
          </button>
        </div>
        <Swiper
          direction="horizontal"
          slidesPerView={5}
          slidesPerGroup={5}
          spaceBetween={30}
          modules={[Grid, Navigation]}
          onInit={(swiper) => {
            //@ts-ignore
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            //@ts-ignore
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          pagination={{ clickable: true }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          className="slider-box"
          breakpoints={{
            1024: {
              slidesPerView: 5,
              slidesPerGroup: 5,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 20,
            },
          }}
        >
          {bestCbt.map((cbt: any, index: any) => (
            <SwiperSlide key={cbt.examIdx} className="slider">
              <Link href={`/exam/detail/${cbt?.examIdx}`}>
                <CbtSlide cbt={cbt} index={index} />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </StyledWrapper>
  );
};

export default ScreenCbtBest;

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1148px;
  margin: 50px auto 80px;

  .slider-wrapper {
    position: relative;

    .swiper {
      text-align: center;

      .swiper-wrapper {
        width: calc(100% / 5);
        .swiper-slide {
        }
      }
    }
    .slider-box {
      .slider {
        width: auto;
        overflow: hidden;
        text-align: center;
        position: relative;
        margin-top: 20px;
      }
    }

    .arrow-wrapper {
      width: 100%;
      height: 40px;
      position: absolute;
      top: 100px;
      .arrow {
        width: 40px;
        height: 40px;
        border: 1px solid #ddd;
        border-radius: 50%;
        background: #fff;
        position: absolute;
      }
      .arrow-left {
        width: 40px;
        height: 40px;
        border: 1px solid #ddd;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        left: -20px;
        z-index: 99;
        svg {
          transform: rotate(180deg);
        }
      }
      .arrow-right {
        width: 40px;
        height: 40px;
        border: 1px solid #ddd;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        right: -20px;
        z-index: 99;
      }

      .swiper-button-disabled {
        display: none;
      }
    }
  }
`;
