import React from 'react';
import styled from 'styled-components';
import useQueryFn from 'app.hooks/useQueryFn';
import { useQueries } from '@tanstack/react-query';
import { axiosClient } from 'src/lib/api/axios';
import useResponsive from '../../../../../utils/OneQuestion/hooks/useResponsive';
import CategoryMobile from 'app.feature/academy/cbt.category/component/mobile/CategoryMobile';
import CategoryPc from 'app.feature/academy/cbt.category/component/pc/CategoryPc';
import { theme } from 'app.styled/theme';

const useExamsQueries = ({ queries, enabled = true }: { queries: any; enabled?: boolean }) => {
  const result = useQueries({
    queries: queries.map((query: any) => {
      return {
        queryKey: ['/apiV2/exams', query],
        queryFn: () => axiosClient({ method: 'GET', url: '/apiV2/exams', data: query }),
        enabled,
        staleTime: 0,
      };
    }),
  });
  return result;
};

const ScreenCbtCategory = () => {
  const { isMobile } = useResponsive(768);

  const { data: examTypes } = useQueryFn<any, any>({
    queryKey: ['/apiV2/exam-types'],
    queryStringOptions: { indices: false },
  });

  const examList = useExamsQueries({
    queries: examTypes.map((type: any) => ({ examTypeIds: type.id })),
  }).map(({ data }) => data);

  return (
    <StyledWrapper>
      {isMobile ? (
        <CategoryMobile examType={examTypes} />
      ) : (
        <CategoryPc examType={examTypes} examList={examList} />
      )}
    </StyledWrapper>
  );
};

export default ScreenCbtCategory;

const StyledWrapper = styled.div`
  max-width: 1148px;
  margin: 50px auto;

  @media ${theme.device.mobile} {
    margin: 0;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
`;
