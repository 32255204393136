import React, { useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import ArrowPullSvg from 'src/app.feature/academy/component/icon/arrow-pull.svg';
import CategoryList from 'app.feature/academy/cbt.category/component/pc/CategoryList';
import CategoryButton from 'app.feature/academy/cbt.category/component/pc/CategoryButton';

interface CategoryProps {
  examType?: any;
  examList?: any;
}

const Category: React.FC<CategoryProps> = ({ examType, examList }) => {
  const router = useRouter();
  const [showAll, setShowAll] = useState(false);

  const handleToggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const handleClickParentCategory = (url: string) => {
    router.push({
      pathname: `/exam/${url}`,
    });
  };

  return (
    <>
      <CategoryWrapper>
        {(examType || [])?.map((category: any, index: number) => {
          const { id, name, url } = category;
          const filteredExamList = examList[index];
          return (
            <CategoryBox key={id}>
              <CategoryButton
                handleClickParentCategory={handleClickParentCategory}
                name={name}
                url={url}
              />
              <CategoryList examList={filteredExamList.nodes} showAll={showAll} />
            </CategoryBox>
          );
        })}
      </CategoryWrapper>
      <ButtonWrapper>
        <button
          className={`toggle-button ${showAll ? 'rotated' : ''}`}
          onClick={handleToggleShowAll}
        >
          <ArrowPullSvg />
        </button>
      </ButtonWrapper>
    </>
  );
};

export default Category;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-top: 1px solid #ddd;
  border-bottom: 2px solid #666;
`;

const CategoryBox = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;

  .toggle-button {
    width: 31.5px;
    height: 17px;
    border: none;
    background-color: #888;
    color: #fff;
    text-align: center;

    &.rotated svg {
      transform: rotate(180deg);
      margin-top: 0;
      margin-bottom: 4px;
    }

    svg {
      transform: translateY(-50%);
      transition: transform 0.5s ease;
      transform-origin: center;
      margin-top: 5px;
    }
  }
`;
