export const convertExamQueryString = (query: any) => {
  const sortKey: string = query.sorts ? (query.sorts as string) : 'viewCount';
  const keyword: string = query.keyword ? (query.keyword as string) : '';

  const queryObj = {
    //examTypeIds: query?.categoryId ? [query?.categoryId] : examTypeIds,
    page: query.page ? Number(query.page) : 1,
    pageSize: 15,
    sorts: convertSortKey(sortKey),
    keyword: keyword,
  };

  return queryObj;
};

export const convertSortKey = (sort: string) => {
  if (sort === 'createdAt') return 'createdAt,desc';
  if (sort === 'likeCount') return 'likeCount,desc';
  return 'viewCount,desc';
};
