import React from 'react';
import ScreenCbtBest from 'src/app.feature/academy/cbt.best/screen/ScreenCbtBest';
import ScreenCbtCategory from 'src/app.feature/academy/cbt.category/screen/ScreenCbtCategory';
import ScreenCbtList from 'src/app.feature/academy/cbt.list/screen/ScreenCbtList';
import useResponsive from '../../../../utils/myStudyReview/OneQuestion/hooks/useResponsive';

const ScreenCbtHome = () => {
  const { isMobile } = useResponsive(768);

  return (
    <>
      <ScreenCbtCategory />
      {!isMobile && <ScreenCbtBest />}
      <ScreenCbtList />
    </>
  );
};

export default ScreenCbtHome;
