import React, { useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { projectConfigState } from 'recoil/Academy/projectConfigState';
import FavoriteIconSvg from 'app.feature/academy/component/icon/heart-icon-regular.svg';
import FavoriteIconSolidSvg from 'app.feature/academy/component/icon/heart-icon-solid.svg';
import { toggleFavorite } from 'app.feature/academy/module/toggleFavorite';
import { useCurrentUser } from 'app.hooks/useCurrentUser';
import { useRouter } from 'next/router';

const CbtListMobile = ({ exam, favorites, setFavorites }: any) => {
  const router = useRouter();
  const { user } = useCurrentUser();
  const { loginPageUrl } = useRecoilValue(projectConfigState);
  const { logo } = useRecoilValue(projectConfigState);
  const [likeCount, setLikeCount] = useState(exam?.likeCount);

  const handleToggleFavorite = (examId: string) => {
    if (!user) {
      router.push({ pathname: loginPageUrl, query: { next: window.location.href } });
      return;
    }
    toggleFavorite({
      examId,
      isFavorite: favorites[examId],
      setFavorites,
      setLikeCount,
    });
  };

  return (
    <StyledWrapper>
      <div className="list-wrapper">
        <Link href={`/exam/detail/${exam.examIdx}`} className="list-link">
          <div className="img-wrapper">
            {exam.examUrl ? (
              <img src={exam.examUrl} alt={exam.examName} />
            ) : (
              <div className="empty-img">
                <img src={logo} alt="" />
              </div>
            )}
          </div>
          <div className="text-wrapper">
            <small className="institution">
              {exam.institution ? exam.institution : '시행기관'}
            </small>
            <h1>{exam.examName}</h1>
            <div className="text-box">
              <small>총회차: {exam.examDetailCount}</small>
              <small>총 문제 수: {exam.problemCount}</small>
              <small>조회수: {exam.viewCount}</small>
            </div>
          </div>
        </Link>
        <div className="favorite">
          <button onClick={() => handleToggleFavorite(exam.examIdx)}>
            {favorites[exam.examIdx] ? <FavoriteIconSolidSvg /> : <FavoriteIconSvg />}
          </button>
          <span>{likeCount}</span>
        </div>
      </div>
      {/*  );*/}
      {/*})*/}
      {/*) : (*/}
      {/*  <EmptyList>*/}
      {/*    <p>검색 결과가 없습니다.</p>*/}
      {/*    <span>다른 필터를 선택해보세요.</span>*/}
      {/*  </EmptyList>*/}
      {/*)}*/}
    </StyledWrapper>
  );
};

export default CbtListMobile;

const StyledWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  .list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #f4f4f4;
    .list-link {
      width: 100%;
      display: flex;
      .img-wrapper {
        width: 30%;
        max-width: 60px;
        img {
          width: 100%;
        }
      }
      .text-wrapper {
        width: 80%;
        max-width: calc(100% - 80px);
        margin: 0 24px 0 15px;
        .institution {
          font-size: 12px;
          font-weight: 400;
          color: #333;
        }
        h1 {
          font-size: 16px;
          font-weight: 400;
          color: #333;
          margin: 10px 0;
        }
        .text-box {
          display: flex;
          justify-content: flex-start;
          gap: 16px;
          small {
            font-size: 12px;
            color: #333;
            position: relative;
            &:after {
              content: '';
              width: 1px;
              height: 7px;
              background-color: #ddd;
              position: absolute;
              right: -9px;
              top: 50%;
              transform: translateY(-50%);
            }
            &:last-child {
              margin-right: 0;
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .favorite {
      text-align: center;
      button {
        background: none;
        border: none;
        cursor: pointer;
      }
      span {
        display: block;
        margin-top: 5px;
        line-height: initial;
        font-size: 16px;
        color: #999;
      }
    }

    .empty-img {
      width: 60px;
      height: 60px;
      border-radius: 20px;
      background-color: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80%;
        height: auto;
      }
    }
  }
`;

const EmptyList = styled.div`
  padding: 76px 0;
  text-align: center;
  p {
    font-size: 20px;
    color: #333;
    font-weight: 400;
  }
  span {
    font-size: 16px;
    color: #999;
    font-weight: 300;
  }
`;
