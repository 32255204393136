import React, { useState } from 'react';
import styled from 'styled-components';
import CbtListTableRow from './CbtListTableRow';
import CbtListMobile from 'app.feature/academy/cbt.list/component/CbtListMobile';
import useResponsive from '../../../../../utils/OneQuestion/hooks/useResponsive';

type CbtTableProps = {
  examList: any;
  likeList: any;
};

const CbtListTable: React.FC<CbtTableProps> = ({ examList, likeList }) => {
  const { isMobile } = useResponsive(768);
  const likeListIds = (likeList || []).map((item: any) => item.examIdx);
  const [favorites, setFavorites] = useState<{ [key: string]: boolean }>(
    likeListIds.reduce((acc: any, id: string) => {
      acc[id] = true;
      return acc;
    }, {})
  );

  if (isMobile) {
    return (
      <>
        {examList?.length > 0 ? (
          examList.map((exam: any, index: any) => (
            <CbtListMobile
              key={index}
              exam={exam}
              favorites={favorites}
              setFavorites={setFavorites}
            />
          ))
        ) : (
          <p>검색 결과가 없습니다.</p>
        )}
      </>
    );
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>시행기관</th>
          <th>시험명</th>
          <th>총 회차</th>
          <th>총 문제 수</th>
          <th>조회수</th>
          <th>나의 관심시험 추가 수</th>
        </tr>
      </thead>
      <tbody>
        {examList?.length > 0 ? (
          examList.map((exam: any, index: any) => (
            <CbtListTableRow
              exam={exam}
              index={index}
              favorites={favorites}
              setFavorites={setFavorites}
            />
          ))
        ) : (
          <tr className="empty">
            <td colSpan={6}>검색 결과가 없습니다.</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default CbtListTable;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #fff;

  thead {
    background-color: #fff;
    border-top: 1px solid #666;
    border-bottom: 1px solid #ddd;
    height: 54px;

    th {
      font-size: 15px;
      color: #333;
      text-align: center;
    }
  }

  .empty {
    text-align: center;
    width: 100%;
  }
`;
