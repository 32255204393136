import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import useQueryFn from 'app.hooks/useQueryFn';
import CbtListTable from 'app.feature/academy/cbt.list/component/CbtListTable';
import CbtListSortHeader from 'app.feature/academy/cbt.list/component/CbtListSortHeader';
import CbtListTablePagination from 'app.feature/academy/cbt.list/component/CbtListTablePagination';
import { convertExamQueryString } from 'app.feature/academy/module/convertExamQueryString';
import { useCurrentUser } from 'src/app.hooks/useCurrentUser';
import { theme } from 'app.styled/theme';

const ScreenCbtList = () => {
  const router = useRouter();
  const pageSize = 15;
  const page = router.query.page ? Number(router.query.page) : 1;
  const sortKey = router.query.sorts ? (router.query.sorts as string) : 'viewCount';
  const type = router.query?.type ?? '';
  const { user } = useCurrentUser();

  const { data: userLikeCbt } = useQueryFn<any, any>({
    queryKey: ['/apiV2/user/me/likes/exams'],
    queryStringOptions: { indices: false },
    enabled: Boolean(user),
  });

  const { data: examTypes } = useQueryFn<any, any>({
    queryKey: ['/apiV2/exam-types'],
    queryStringOptions: { indices: false },
  });

  const { data: exams } = useQueryFn<any, any>({
    queryKey: [
      type ? `/apiV2/exams/${type}` : '/apiV2/exams',
      convertExamQueryString(router.query),
    ],
    queryStringOptions: { indices: false },
  });

  const handlePageChange = (page: number) => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, page },
    });
  };

  // 시험 타입별 시험 목록 조회
  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, sorts: e.target.value, page: 1 },
    });
  };

  return (
    <TableWrapper>
      <CbtListSortHeader
        sortKey={sortKey}
        handleSortChange={handleSortChange}
        itemCount={exams?.totalCount}
      />
      <CbtListTable examList={exams?.nodes} likeList={userLikeCbt} />
      <CbtListTablePagination
        total={exams?.totalCount}
        pageSize={pageSize}
        current={page}
        handlePageChange={handlePageChange}
      />
    </TableWrapper>
  );
};

export default ScreenCbtList;

const TableWrapper = styled.div`
  max-width: 1148px;
  margin: 50px auto;
  @media ${theme.device.mobile} {
    margin: 0;
  }
`;
