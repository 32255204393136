import Link from 'next/link';
import { toggleFavorite } from 'app.feature/academy/module/toggleFavorite';
import FavoriteIconSvg from 'src/app.feature/academy/component/icon/heart-icon-regular.svg';
import FavoriteIconSolidSvg from 'src/app.feature/academy/component/icon/heart-icon-solid.svg';
import useQueryFn from 'src/app.hooks/useQueryFn';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { projectConfigState } from 'recoil/Academy/projectConfigState';
import React, { useState } from 'react';
import { useCurrentUser } from 'src/app.hooks/useCurrentUser';
import styled from 'styled-components';

const CbtListTableRow = ({ exam, index, favorites, setFavorites }: any) => {
  const router = useRouter();
  const { user } = useCurrentUser();
  const { loginPageUrl } = useRecoilValue(projectConfigState);
  const [likeCount, setLikeCount] = useState(exam?.likeCount);
  const { logo } = useRecoilValue(projectConfigState);

  const handleToggleFavorite = (examId: string) => {
    if (!user) {
      router.push({ pathname: loginPageUrl, query: { next: window.location.href } });
      return;
    }
    toggleFavorite({
      examId,
      isFavorite: favorites[examId],
      setFavorites,
      setLikeCount,
    });
  };

  return (
    <TableRow key={index}>
      <td className="institution">
        {exam?.examUrl ? (
          <div className="img-wrapper">
            <img src={exam.examUrl} alt={exam.examName} />
            {exam?.institution ? exam.institution : '없음'}
          </div>
        ) : (
          <div className="empty-img-wrapper">
            <div className="empty-img">
              <img src={logo} alt={exam.examName} />
            </div>
            {exam?.institution ? exam.institution : '없음'}
          </div>
        )}
      </td>
      <td className="title">
        <Link href={`/exam/detail/${exam.examIdx}`}>{exam.examName}</Link>
      </td>
      <td>{exam.examDetailCount}</td>
      <td>{exam.problemCount}</td>
      <td>{exam.viewCount}</td>
      <td className="favorite">
        <button onClick={() => handleToggleFavorite(exam.examIdx)}>
          {favorites[exam.examIdx] ? <FavoriteIconSolidSvg /> : <FavoriteIconSvg />}
        </button>
        <span>{likeCount}</span>
      </td>
    </TableRow>
  );
};

export default CbtListTableRow;

const TableRow = styled.tr`
  height: 100px;

  td {
    text-align: center;
    border-bottom: 1px solid #ddd;
  }

  .institution {
    .img-wrapper {
      img {
        width: 60px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
    .empty-img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .empty-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #f4f4f4;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        > img {
          width: 80%;
          height: auto;
        }
      }
    }
  }

  .favorite {
    span {
      display: block;
      margin-top: 5px;
      line-height: initial;
      font-size: 16px;
      color: #999;
    }
  }

  .title {
    text-align: left;
  }
`;
